

.botonera{
    width: 100%;
    position: fixed;
    bottom: 0; 
    display: flex;
    background-color: #2d313a;
    
    z-index: 110;
}

@media (max-width:1000px){
    .botonera{
        /* padding-top: 100px; */
        font-size: 3.4vw;
    }
}
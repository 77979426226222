body{
    background-color: black;
}

.viewtrailer{
    min-height: 700px;
    background-size: cover;
    background-position: center;
    /* display: flex;   */
    /* flex-wrap: wrap;    */
    /* align-items: flex-end; */
}
.boton{
    background-color: #0F1014;
    color: white;
    outline: none;
    border: 1px solid white;
    padding: 10px 15px;
    cursor: pointer;
    margin-bottom: 10px;
}
.reproductor{
    width: 100%;
    height: 300px;
}

.trending{
    /* margin-left: 25px; */
    display: flex;
    flex-wrap:wrap ;
    gap: 10px;
    justify-content: center ;
    align-items: center ;
}


.card{
     position: relative;
     display: flex; 
     flex-direction: column; 
     width: 250px;
     height: 500px;  
    padding: 5px;
    margin: 0 10px 20px 0;
    border-radius: 10px;
    font-family: "Montserrat", sans-serif;
}

.card:hover{

    -webkit-box-shadow: -1px 1px 41px 28px rgba(95,134,250,0.6);
    -moz-box-shadow: -1px 1px 41px 28px rgba(95,134,250,0.6);
    box-shadow: -1px 1px 41px 28px rgba(95,134,250,0.6);
    /* box-shadow: 5px 10px 20px 1px rgb(78, 170, 246) !important; */
    transform: scale(1.2);
    z-index: 100;
    transition: all 0.2s linear;
}

.card-body{
    padding: 2rem 0 !important;
}

.ojo{
    text-decoration: none;
    color: beige;
}

